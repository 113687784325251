
if($('.top-form').length) {

  //バリデーション
  $('input.is-required').on('blur',function()
  {
    let error;
    let value = $(this).val();
    if(value == "")
    {
      error = true;
    }
    else if(!value.match(/[^\s\t]/))
    {
      error = true;
    }

    if(error)
    {
      //エラー時の処理
      $(this).addClass('is-error');

      //エラーで、エラーメッセージがなかったら
      if(!$(this).nextAll('span.error-info').length)
      {
        //メッセージを後ろに追加
        $(this).after('<span class = "error-info">* 必須項目です</span>');
      }
    } else {
      $(this).removeClass('is-error');

      //エラーじゃないのにメッセージがあったら
      if($(this).nextAll('span.error-info').length)
      {
        //消す
        $(this).nextAll('span.error-info').remove();
      }
    }
  });

  $("input[type='email']").blur(function(){
    if(!$(this).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
      error = true;
    } else {
      error = false;
    }

    if(error)
    {
      //エラー時の処理
      $(this).addClass('is-error');

      //エラーで、エラーメッセージがなかったら
      if(!$(this).nextAll('span.error-info').length)
      {
        //メッセージを後ろに追加
        $(this).after('<span class = "error-info">* 使用可能なメールアドレスを入力してください</span>');
      }
    } else {
      $(this).removeClass('is-error');

      //エラーじゃないのにメッセージがあったら
      if($(this).nextAll('span.error-info').length)
      {
        //消す
        $(this).nextAll('span.error-info').remove();
      }
    }
  });

  $('input.is-email-check2').on('blur',function()
  {
    let error;

    if($(this).val() === $('input.is-email-check1').val()) {
      console.log('一致しました');
    } else {
      error = true;
      console.log('一致しません');
    }

    if(error)
    {
      //エラー時の処理
      $(this).addClass('is-error');

      //エラーで、エラーメッセージがなかったら
      if(!$(this).nextAll('span.error-info').length)
      {
        //メッセージを後ろに追加
        $(this).after('<span class = "error-info">入力項目が一致しません</span>');
      }
    } else {
      $(this).removeClass('is-error');

      //エラーじゃないのにメッセージがあったら
      if($(this).nextAll('span.error-info').length)
      {
        //消す
        $(this).nextAll('span.error-info').remove();
      }
    }
  });


  $('.contact-input__form-submit').click(function(e) {
    $('.form_submit').prop('disabled', false);
    let error;

    if($('input.is-name').val() == ""){
      error = true;
    } else {
      error = false;
    }

    if($('input.is-email-check1').val() == ""){
      error = true;
    } else {
      error = false;
    }

    if($('input.is-email-check2').val() === $('input.is-email-check1').val() && $('input.is-email-check1').val() !== "") {
      error = false;
    } else {
      error = true;
    }


    // error = true;

    if(error)
    {
      window . alert( '入力内容に不備があります。' );
      return false;
      console.log('送信できません');
    } else {
      return true;
      console.log('送信');
    }
  })
}
