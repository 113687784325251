import swiper from 'swiper';

var worksMain = new swiper ('.js-hero-slider', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  effect: "fade",
  slidesPerView: 'auto',
  speed:1000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  spaceBetween: 0,
  // centeredSlides: true,
  observer: true,
  // observeParents: true,
  preloadImages: false,
  // noSwiping: true,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    768: {
      // direction: 'horizontal',
      allowTouchMove: true,
      centeredSlides: true,
      // slidesPerView: 1,
      // spaceBetween: 20
    }
  },
  thumbs: {
    // swiper: worksNav
  },
  on: {
    init: function () {
      // $(".js-slider-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
      // underLine(this.activeIndex);
    },
    slideChange: function () {
      // $(".js-slider-nav .swiper-slide").removeClass("is-active");
      // $(".js-slider-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
      // underLine(this.activeIndex);
    },
  }
});
