// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
// import isMobile from './modules/common/is-mobile.js';
import './modules/top/mainSlider.js';
import './modules/common/smoothScroll.js';
// import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';//スマホメニュー
// import './modules/common/screenWidth.js';
import './modules/common/check.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});


//typekit
(function(d) {
  var config = {
    kitId: 'aml3myj',
    scriptTimeout: 3000,
    async: true
  },
  h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wfinactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
})(document);
